.root {
  font-style: normal;
  color: var(--black);
  position: relative;
  width: 100%;
}

.input {
  background: var(#fff);
  border: 0;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.bordered {
  border-radius: 6px;
}

.input::placeholder {
  color: #aaa;
}

.whiteOnBlue .input:focus {
  border: 2px solid var(--black);
}

.input:focus {
  border: 2px solid var(--blue);
}

.error .input {
  border-color: var(--red);
}

.errorText {
  font-size: 14px;
  color: var(--red);
  font-weight: normal;
  margin-top: 8px;
  position: absolute;
  bottom: -1.57em;
}

@media screen and (max-width: 600px) {
  .errorText {
    position: static;
  }
}
