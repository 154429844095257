.root {
  background: #dddddd;
  border-radius: 12px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.item {
  padding-left: 12px;
  padding-right: 12px;
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #111111;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.box {
  transform: translateX(100%);
  background: #aaaaaa;
  border-radius: 12px;
  cursor: default;
  display: block;
  height: 100%;
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: 0.3s ease all;
}

.activeItem {
  color: #fafafa;
}

.active .box {
  transform: translateX(0);
}

.activeItem svg path {
  fill: #fafafa;
}
